import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'

import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'

const PaslanmazHasirTelPage316 = () => {
  const { getImageByName } = useStore()

  return (
    <>
      <SEO
        title='316 Kalite Paslanmaz Hasır Tel - Erez Kaynak'
        description='316 Kalite Paslanmaz Hasır Tel Firmamız 316 kalite paslanmaz tel malzeme kullanarak punta kaynaklı paslanmaz hasır tel imalatı yapmaktadır. 316 kalite paslanmaz hasırlar 304e göre daha fazla ısıya ve kimyasal etkenlere (asit, deniz suyu vb) karşı dirençlidir. Tel kalınlığı 2mm den 8mm tel'
        keywords='paslanmaz hasır tel,316 kalite paslanmaz hasır tel kullanım alanları,paslanmaz kafes tel,paslanmaz çesan,paslanmaz fens,paslanmaz tel kafes,paslanmaz puntalı tel,paslanmaz kafes,paslanmaz hasır tel fiyatları,paslanmaz kafes tel fiyatları,paslanmaz çesan fiyatları,paslanmaz fens fiyatları,paslanmaz tel kafes fiyatları,paslanmaz puntalı tel fiyatları,paslanmaz kafes fiyatları,paslanmaz hasır tel imalatı,paslanmaz hasır tel istanbul,özel paslanmaz hasır tel'
        url='316-kalite-paslanmaz-hasir-tel'
        imgurl={getImageByName('304kalite').sm.src}
      />

      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='316 Kalite Paslanmaz Hasır Tel' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız <strong>316 kalite paslanmaz tel</strong> malzeme kullanarak <strong>punta kaynaklı paslanmaz hasır tel</strong>{' '}
                imalatı yapmaktadır.
              </p>
              <p>
                <strong>316 kalite paslanmaz hasırlar</strong> 304'e göre daha fazla ısıya ve kimyasal etkenlere (asit, deniz suyu vb) karşı
                dirençlidir.
              </p>

              <p>
                Tel kalınlığı 2mm den 8mm tel kalınlığına kadar <strong>paslanmaz hasır tel</strong> imalatını yapabiliyoruz.
              </p>
              <p>
                Göz aralığı standart olarak merkezden merkeze 15mm, 20mm, 25mm ve katlarında <strong>paslanmaz hasır tel</strong> imalatı
                yapılabilir.
              </p>
              <p>
                15mm katı olan 15x90mm veya 25mm katı olan 50x125mm gibi istediğiniz her ölçüde <strong>paslanmaz hasır tel</strong>{' '}
                yapabilmekteyiz.
              </p>
              <p>
                Özel göz aralıklarında (örn; 26,3×37,5mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan paslanmaz
                hasırı uygun bir maliyet ile üretiyoruz.
              </p>
              <p>
                İstenildiği takdirde malzemenin <strong>paslanmaz 316 kalite sertifikası</strong> verilmektedir.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center'>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('304kalite')} alt='paslanmaz 316 kalite sertifikası' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}

export default PaslanmazHasirTelPage316
